import React from 'react';
import {
  ArrayParam,
  BooleanParam,
  StringParam,
  useQueryParams,
} from 'use-query-params';
import { graphql, useStaticQuery } from 'gatsby';
import { navigate } from 'gatsby';
import Layout from '../../../modules/layout';
import Search from '../../../components/ui/filterSearch';
import ResultsList from '../../../components/ui/resultsList';
import { useWinnerFilters } from '../../../hooks/useWinnerFilters';
import { useLocale } from '../../../context/locale.context';
import * as styles from './styles.module.scss';
import Container from '../../../modules/container';
import Headline from '../../../components/ui/headline';
import Text from '../../../components/ui/text';
import { getCopy } from '../../../helpers/copy';

const PARAMS_CONFIG = {
  tagId: StringParam,
  awardId: ArrayParam,
  regionId: ArrayParam,
  countryId: ArrayParam,
  disciplineId: ArrayParam,
  categoryId: ArrayParam,
  yearId: ArrayParam,
  sort: StringParam,
  find: StringParam,
  seed: StringParam,
  inspiration: BooleanParam,
  profile: StringParam,
};

const WinnerOverview = () => {
  const { locale } = useLocale();
  const [query, _] = useQueryParams(PARAMS_CONFIG);

  const { allContentfulSearchChip, allContentfulMicrocopy } = useStaticQuery(
    graphql`
      query {
        allContentfulSearchChip(
          filter: {
            content_search: {
              elemMatch: {
                page: { elemMatch: { slug: { eq: "winner-ranking" } } }
              }
            }
          }
        ) {
          nodes {
            behaviour
            queryParameters
            tag
            label
            node_locale
            queryParameters
          }
        }
        allContentfulMicrocopy {
          nodes {
            key
            value
            node_locale
          }
        }
      }
    `
  );

  const localizedChips = {
    nodes: allContentfulSearchChip.nodes.filter(
      (chip) =>
        chip.node_locale === locale && chip.behaviour !== 'Query Parameter'
    ),
  };

  const onSubmit = (searchTerm) => {
    const params = new URLSearchParams(location.search);
    params.set('find', searchTerm.split('=').pop());
    navigate(`?${params.toString()}`, {
      replace: true,
    });
  };

  const searchByTag = (tag) => {
    navigate(`?tagId=${tag}`, {
      replace: true,
    });
  };

  const resetSearchTerm = () => {
    const params = new URLSearchParams(location.search);
    params.set('find', '');
    navigate(`?${params.toString()}`, {
      replace: true,
    });
  };

  const onInspire = () => {
    navigate('?inspiration=1', { replace: true });
  };

  const microcopy = allContentfulMicrocopy.nodes
    .filter((node) => node.node_locale === locale)
    .reduce((acc, curr) => {
      return {
        ...acc,
        [curr.key]: curr.value,
      };
    }, {});

  const queryFilters = {
    awards: query.awardId ? query.awardId.map(Number) : [2],
    disciplines: query.disciplineId ? query.disciplineId.map(Number) : [],
    categories: query.categoryId ? query.categoryId.map(Number) : [],
    region: query.regionId ? query.regionId.map(String) : [],
    countries: query.countryId ? query.countryId.map(String) : [],
    time: query.yearId ? query.yearId.map(Number) : query.profile ? [4] : [0],
    inspiration: query.inspiration,
    tag: query.tagId,
    sort: query.sort ? query.sort : 'random',
    find: query.find,
    profile: query.profile,
    seed: query.seed,
  };

  return (
    <Layout
      title={'title.winnerOverview'}
      description={'desc.winnerOverview'}
      slug={'winner-ranking/winner-overview'}
    >
      <div className={styles.headlineOuterContainer}>
        <Container className={styles.headlineContainer}>
          <Headline
            text={getCopy('label.ifdaFilterHeadline', microcopy)}
            level="h1"
            type="h1"
          />
          <Text as="p" type="p1" className={styles.subheadline}>
            {getCopy('label.ifdaFilterSubheadline', microcopy)}
          </Text>
        </Container>
      </div>
      <Search
        onSubmit={onSubmit}
        onTagClick={searchByTag}
        resetSearchTerm={resetSearchTerm}
        onInspire={onInspire}
        initSearchTerm={query.find}
        chips={localizedChips.nodes}
        type="Projects"
        placeholder={getCopy('placeholder.whatDoYouWantToExplore', microcopy)}
      />
      <ResultsList
        queryFilters={queryFilters}
        searchTerm={query.find}
        resetSearchTerm={resetSearchTerm}
        filtersHook={useWinnerFilters}
        teaserType="design"
        tags={localizedChips.nodes}
        noResultsLabelKey="results.projects.noResults"
        isWinnerFilterlist
        filtersApiParam="project"
      />
    </Layout>
  );
};

export default WinnerOverview;
